import * as React from 'react';
import Box from '@mui/material/Box';
import { Chip as MuiChip } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Sidebar from './Sidebar';

import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Content from './Content';
import FirstOffer from './FirstOffer';
import Cookies from 'js-cookie';
import $ from 'jquery';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
var firstOffer = '';
var firstOffer1 = '';
const baseUrl ='https://trustedreviews.createch.company/assets/images/';
const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Dashboard',
    description:
      'This item could provide a snapshot of the most important metrics or data points related to the product.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Mobile integration',
    description:
      'This item could provide information about the mobile app version of the product.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Available on all platforms',
    description:
      'This item could let users know the product is available on all platforms, such as web, mobile, and desktop.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...theme.applyStyles('dark', {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));
export let offerData = {};
export default function Features() {
  function gotoLink(event,pkey,offer, link, hash, href){
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    var adata = {
      name:offer+" Box Clicked",
      lazy_pkey: pkey, 
      engagement:1,
    };
    window["lazysauce_action"](adata).then(function(successhash){
      console.log('successhash'+JSON.stringify(successhash));
    //window.lazysauce_action(adata).then(function(successhash){
      var url = link;
      url = url.replace('{HASH}',successhash);
      url = url.replace('{HASH}',successhash);
      url = url.replace('{CVAL}',"");
      url = url.replace('{SVAL}',"");
      
      window.open(url,'_blank'); 
    }).catch(() => {
      link = link.replace('{HASH}', hash);
      link = link.replace('{HASH}', hash);
      link = link.replace('{CVAL}',"");
      link = link.replace('{SVAL}',"");
      window.open(link,'_blank'); 
    });

    fetch(href)
    .then(response => response.text())
    .then(data => {
      $('body').append(data);
     
    })
    
   

  }
  
  const [vertical, setVertical] = React.useState({});
  const [pkey, setPkey] = React.useState({});
  const [hash, setHash] = React.useState({});
  const [isOpen, setIsOpen] =React.useState({});
  const [ isMobile, setIsMobile ] = React.useState(window.innerWidth <= 900);
  
  const handleResize = () => setIsMobile(window.innerWidth <= 900);

  
React.useEffect(() => {
    window.addEventListener("resize", () => handleResize);
    return () => window.removeEventListener("resize", () => handleResize);
  }, []);
  const [offers, setOffers] = React.useState([])
React.useEffect(() => {
  /* setPkey(Cookies.get('Lazy_pkey'));
  console.log('pkey1'+JSON.stringify(pkey)); */


    async function getVertical() {
        const url = "https://ping.rebootmedia.net/get_vertical.php";
        let response = await fetch(url);
        response = await response.json();
        setVertical(response);
    }  
    getVertical();
}, []);


  React.useEffect(() => {
    async function getOffers() {
      const url = "https://ping.rebootmedia.net/get_tr_reviews_offers.php";
      let response = await fetch(url)
      response = await response.json()
      setOffers(response);
      
      var offerNames = [];
      {(Object.entries(response) || []).map(([key, value]) => {
        if(key == 0)
        offerData = value;
        offerNames.push((parseInt(key)+1)+'-'+value.name);
      })};
      var datain1 = {
        "name": 'Main Page Visit',
        "value": offerNames.join(','),
        'action': 1 
    };
    window["lazysauce_param"](datain1);
  }
  getOffers();

  window.onload = () => {
    var pkey = sessionStorage.getItem('co_pkey');
    var hash = sessionStorage.getItem('co_hash');
    if(pkey != '' && pkey != null && typeof(pkey) != 'undefined' && hash != '' && hash != null && typeof(hash) != 'undefined') {
      setPkey(sessionStorage.getItem('co_pkey').toString());
      setHash(sessionStorage.getItem('co_hash').toString());
      
    } 
  }
  }, []);
  console.log('pkey'+(pkey));

  console.log('hash'+(hash));
console.log('vertical'+JSON.stringify(vertical));
  return ( 
    <Container id="features" sx={{ py: { xs: 2, sm: 2 } }}>
      <Grid container spacing={6}>
        <Grid className='sectionMain' item xs={12} md={9} sx={{ p: { xs: 0, sm:0 },pl: { xs: 0, sm:0 },pt: { xs: 0, sm:0 },}}>
         
          {(Object.entries(offers) || []).map(([key, value]) => {

     if(typeof(vertical.rating_number) != 'undefined'){
        var rating_numbers = vertical.rating_number.split(',');
        var rating_texts = vertical.rating_text.split(',');
        var rating_number = rating_numbers[key];
        var  rating_text = rating_texts[key];
        var pos= parseInt(key)+1;
            
        var stars_text_arr = vertical.rating_stars_text.split('|');

       /*  if(typeof hash !== 'object' && hash !== null) {
        value.link = value.link.replace('{CVAL}','');
        value.link = value.link.replace('{SVAL}','');
        value.link = value.link.replace('{HASH}',hash);  
        value.link = value.link.replace('{HASH}',hash); 
        } */


        var stars = stars_text_arr[key].split(',');
          
           var stars_text = '';
           stars_text = stars.map((star, index) => {
              if (star == '1') 
                return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-full.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
              else if (star == '0.5') 
                return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-haff.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
              else 
              return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-outline.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
            
          })
        }
          var bulletss = value.bullets.split('|');
          var bullets_text = '';
          bullets_text = bulletss.map((bullet, index) => {
             return (<ListItem sx={{padding: 0}}>
              <ListItemIcon sx={{width:'5%', minWidth: '5%'}}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/green-tick.png`} width="100%" height="100%"/>
                </ListItemIcon>
                <ListItemText sx={{fontSize:'0.8rem', marginTop: '0', marginBottom: '0'}}
                  primary={bullet}
                />
              </ListItem>)            
          })
          if(key == 0)  {
            firstOffer = value;
            firstOffer1 = value;
            return (
              
              <FirstOffer offerValue={firstOffer} vertical={vertical} pkey={pkey} hash={hash}/> 
          )
        }
          else 
            return (
          <Box className="items-row" sx={{background:'#fdfdfd', position:'relative',p:0,display: 'flex',width: '100%',marginBottom: '1rem',paddingTop: '0',paddingBottom: '1rem',boxShadow: '1px 2px 7px 0 rgb(0 0 0 / 30%)',background:'#fff'}}>
           <Link data-hash={hash} data-position={key+1} data-title={value.name} className="inner-items-row offerlink" target="_blank" data-url={"https://trustedreviews.co/home-warranty/go.php?o="+value.id+"&pkey="+pkey+"&hash="+hash+"&type=box&pos="+pos+"&r="+value.link} onClick={(event) => gotoLink(event,pkey, value.name, value.link, hash,'https://trustedreviews.co/home-warranty/go.php?o='+value.id+'&pkey='+pkey+'&hash='+hash+'&type=box&pos='+pos+'&r='+value.link)} data-link={value.link} href={"https://trustedreviews.co/home-warranty/go.php?o="+value.id+"&pkey="+pkey+"&hash="+hash+"&type=box&pos="+pos}  underline="none"  sx={{display:'flex',color: 'inherit',width:'100%'}} >
          <Grid  className="items-first-column" item xs={12} md={3} sx={{ display: 'flex',pl:1,pt:1}}>
          <Typography component="div" className="reviews-box" sx={{display: 'flex',alignItems:'center', minHeight:'60px'}}>
          <Typography target="_blank" underline="none">
          <img src={baseUrl+value.logo}  width="175" alt={value.name}/>
          </Typography>
          </Typography>
          </Grid>
          <Grid className="items-second-column" item xs={12} md={6}>
          <Typography variant="h3" gutterBottom sx={{           
              color: '#000',
            }}>
         {value.name}
      </Typography>
      <List sx={{p:0}} style={{display:(isOpen !=key && isMobile) ? "none":'block'}}>	  
               {bullets_text}             
            </List> 
          </Grid>
          <Grid className="items-third-column" item xs={12} md={3} sx={{pl:{xs:'0%',sm: '0%'}}}>
            <Box className="offer-discount-dsk" sx={{borderRadius: '0 4px 0 4px', backgroundColor: '#bdded9', pt:0, pb:0.5, pl:1, pr:1, my: { xs: '0.8rem', sm: '0rem' },}} style={{visibility: (value.discount_text!='') ? 'visible' : 'hidden' }}>
			<Typography component="div" variant="text_box" sx={{lineHeight:'1', display:'flex',paddingTop:'6px',paddingBottom:'6px'}}>
            <Typography component="span" variant="current_deal" sx={{lineHeight:'1.1',fontSize:'0.805rem', width:'60%'}} >{value.deal_text}:</Typography>
            <Typography component="span" variant="verified_box" sx={{lineHeight:'1'}} className="verifybox" >
            <Typography component="span" className="hide-on-mobile" variant="deal_tick" sx={{lineHeight:'1'}} ></Typography>
            <Typography component="span" variant="current_deal" sx={{lineHeight:'1'}} >Verified</Typography>
            </Typography>
			</Typography>
            <Typography component="div" variant="discount_info" sx={{lineHeight:'1',fontSize:'0.8rem'}}>{value.discount_text}</Typography>
            </Box>
			
            <Box  style={{visibility: (value.discount_text!='') ? 'visible' : 'hidden' }} className="offer-discount-mobile" sx={{textAlign:'center',borderRadius: '0 4px 0 4px', backgroundColor: '#bdded9', pt:0.7, pb:0.7, pl:1, pr:1, mt: { xs: '0rem', sm: '0.8rem' },mb: { xs: '0.5rem', sm: '0.5rem' } }}>    
            <Typography component="span"className="verified_box" variant="verified_box">
            <Typography component="span" sx={{marginRight:'5px'}} variant="deal_tick" ></Typography>
            <Typography component="span" variant="current_deal" sx={{lineHeight:'1',fontSize:'0.805rem', width:'auto'}} >{value.deal_text}:</Typography>
            </Typography> 
            <Typography sx={{textAlign:'center',lineHeight:'1'}}component="div" variant="discount_info" >{value.discount_text}</Typography>
            </Box>
			
			<Typography component="div" variant="view_plans" sx={{height:'auto'}} >
            <Typography className="view_plans_link" variant="view_plans_link" style={{display:'block'}} sx={{position:{ xs: 'relative', sm: 'relative' },marginTop:{ xs: '0', sm: '2.2em' },}}>
             <Typography className="wrapper_btn_view" sx={{'@media (max-width: 480px)': {width: '100%',fontSize:'1.4rem'} }} component="span" variant="wrapper_btn_view" >  VIEW PLANS </Typography>
            </Typography>
		  </Typography>
		  
          </Grid>
		  </Link> 
		  <Typography sx={{marginTop:'0px',lineHeight:'1.1',fontSize:'0.9em'}} style={{display:(isOpen ==key) ? "none":'block'}} component="div"  className="toggleReviews" variant="h3" onClick={() => {
          setIsOpen(key);
        }}> {value.name} Reviews</Typography>
        
          </Box>
            );
          })}
          
		  
		  <Content />
		  
      <FirstOffer offerValue1={firstOffer1} vertical1={vertical}  pkey1={pkey} hash1={hash}/> 
         
        </Grid>
        <Grid
          item
          className='sectionSidebar'		  
          xs={12}
          md={3}
          sx={{ pl:'25px',display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
           <Sidebar />
        </Grid>
      </Grid>
    </Container>
  );
}
