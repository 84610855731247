import * as React from 'react';
import Box from '@mui/material/Box';
import { Chip as MuiChip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import $ from 'jquery';

export default function FirstOffer(props) {
  function gotoLink(event,pkey,offer, link, hash, href){
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    var adata = {
      name:offer+" Box Clicked",
      lazy_pkey: pkey, 
      engagement:1,
    };

    window["lazysauce_action"](adata).then(function(successhash){
      console.log('successhash'+JSON.stringify(successhash));
      //window.lazysauce_action(adata).then(function(successhash){
        var url = link;
        url = url.replace('{HASH}',successhash);
        url = url.replace('{HASH}',successhash);
        url = url.replace('{CVAL}',"");
        url = url.replace('{SVAL}',"");
        
        window.open(url,'_blank'); 
      }).catch(() => {
        link = link.replace('{HASH}', hash);
        link = link.replace('{HASH}', hash);
        link = link.replace('{CVAL}',"");
        link = link.replace('{SVAL}',"");
        window.open(link,'_blank'); 
      });

    fetch(href)
    .then(response => response.text())
    .then(data => {
      $('body').append(data);
   
    })
    
   

  }
  const baseUrl ='https://trustedreviews.createch.company/assets/images/';
  if(typeof(props.offerValue ) == 'undefined'){
    var value = props.offerValue1;
  }
  else {
        var value = props.offerValue;
  }
  if(typeof(props.vertical ) == 'undefined'){
    var vertical = props.vertical1;
  }
  else {
        var vertical = props.vertical;
  }

  if(typeof(props.pkey ) == 'undefined'){
    var pkey = props.pkey1;
  }
  else {
        var pkey = props.pkey;
  }
  if(typeof(props.hash ) == 'undefined'){
    var hash = props.hash1;
  }
  else {
        var hash = props.hash;
  }
  var key = 0;
  if(typeof(value ) != 'undefined' && value != ''){
    if(typeof(vertical.rating_number) != 'undefined'){
    var rating_numbers = vertical.rating_number.split(',');
    var rating_texts = vertical.rating_text.split(',');
    var rating_number = rating_numbers[key];
    var  rating_text = rating_texts[key];
    var pos= parseInt(key)+1;
    var stars_text_arr = vertical.rating_stars_text.split('|');

    /* if(typeof hash !== 'object' && hash !== null) {
      value.link = value.link.replace('{CVAL}','');
      value.link = value.link.replace('{SVAL}','');
      value.link = value.link.replace('{HASH}',hash);  
      value.link = value.link.replace('{HASH}',hash); 
      } */
    var stars = stars_text_arr[key].split(',');
           var stars_text = '';
           stars_text = stars.map((star, index) => {
            if (star == '1') 
              return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-full.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
            else if (star == '0.5') 
              return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-haff.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
            else 
            return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-outline.png`}  width="16" height="16" alt={value.name+' Rating'}/>)
            
          })
        }
          var bulletss = value.bullets.split('|');
          var bullets_text = '';
          bullets_text = bulletss.map((bullet, index) => {
             return (<ListItem sx={{padding: 0}}>
              <ListItemIcon sx={{width:'5%', minWidth: '5%'}}>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/green-tick.png`} width="100%" height="100%"/>
                </ListItemIcon>
                <ListItemText sx={{fontSize:'0.8rem', marginTop: '0', marginBottom: '0'}}
                  primary={bullet}
                />
              </ListItem>)            
          })
  return (     
        <Box className="items-row" sx={{background:'#ffffff',position:'relative',p:0,width: '100%',boxShadow: '1px 2px 7px 0 rgb(0 0 0 / 30%)',background:'#fff'}}>   
              <Link data-hash={hash}  data-title={value.name} className="offerlink" target="_blank" data-link={value.link}data-url={"https://trustedreviews.co/home-warranty/go.php?o="+value.id+"&pkey="+pkey+"&hash="+hash+"&type=box&pos="+pos+"&r="+value.link}  onClick={(event) => gotoLink(event,pkey, value.name, value.link, hash,'https://trustedreviews.co/home-warranty/go.php?o='+value.id+'&pkey='+pkey+'&hash='+hash+'&type=box&pos='+pos+'&r='+value.link)} href={"https://trustedreviews.co/home-warranty/go.php?o="+value.id+"&pkey="+pkey+"&hash="+hash+"&type=box&pos="+pos} underline="none" sx={{display:'flex',color: 'inherit',width:'100%',marginBottom:'15px'}} >
        <Typography id="top_rating_border" sx={{position:'absolute',}} component="div" >
                <Typography component="div" variant='top_rating_border'>
                    <Typography component="div" variant='top_rating_border_in'>
                    <Typography component="span" variant='white_tick_img'></Typography>
                    <Typography component="span" variant="first_offer_special">Editor's Choice</Typography>
                </Typography>
            </Typography>
            </Typography>
            <Box className="items-row" sx={{pt:3.2,display: 'flex',width: '100%',marginBottom: '1rem',paddingBottom: '0rem',background:'#fff'}}>     
          <Grid className="items-first-column" item xs={12} md={3} sx={{ display: 'flex',pl:1,pt:0}}>
          
          <Typography component="div" className="reviews-box" sx={{display: 'flex',alignItems:'center', minHeight:'60px'}}>
          <Typography underline="none">
          <img src={baseUrl+value.logo}  width="175" alt={value.name}/>
          </Typography>
          </Typography>
          </Grid>
          <Grid className="items-second-column" item xs={12} md={6}>
          <Typography variant="h3" gutterBottom sx={{           
              color: '#000',marginTop:0,
            }}> {value.name}
      </Typography>
      <List sx={{p:0}}>
             
      {bullets_text}      
              
            </List>
          </Grid>
          <Grid className="items-third-column" item xs={12} md={3} sx={{pl:{xs:'0%',sm: '0%'}, mt: '-35px'}}>
            <Box className="offer-discount-dsk"  style={{visibility: (value.discount_text!='') ? 'visible' : 'hidden' }} sx={{marginTop:'10px',borderRadius: '0 4px 0 4px', backgroundColor: '#bdded9', pt:0, pb:0.5, pl:1, pr:1, mt: { xs: '1.8rem', sm: '0.5rem' }, mb: { xs: '0.8rem', sm: '0.8rem' }}}>
			<Typography component="div" variant="text_box" sx={{lineHeight:'1', display:'flex',paddingTop:'6px',paddingBottom:'6px'}}>
            <Typography component="span" variant="current_deal" sx={{lineHeight:'1.1',fontSize:'0.805rem', width:'60%'}} >{value.deal_text}:</Typography>
            <Typography component="span" variant="verified_box" sx={{lineHeight:'1'}} className="verifybox">
            <Typography component="span" className="hide-on-mobile" variant="deal_tick" sx={{lineHeight:'1'}} ></Typography>
            <Typography component="span" variant="current_deal" sx={{lineHeight:'1'}} >Verified</Typography>
            </Typography>
			</Typography>
            <Typography component="div" variant="discount_info" sx={{lineHeight:'1',fontSize:'0.8rem'}}>{value.discount_text}</Typography>
            </Box>
            <Box  className="first-offer offer-discount-mobile"  style={{visibility: (value.discount_text!='') ? 'visible' : 'hidden' }} sx={{textAlign:'center',borderRadius: '0 4px 0 4px', backgroundColor: '#bdded9', pt:0.7, pb:0.7, pl:1, pr:1, mt: { xs: '1.8rem', sm: '0.8rem' }, mb: { xs: '0.5rem', sm: '0.5rem' }}}>    
            <Typography component="span"className="verified_box" variant="verified_box">
            <Typography component="span" sx={{marginRight:'5px'}} variant="deal_tick" ></Typography>
			<Typography component="span" variant="current_deal" sx={{lineHeight:'1', width:'auto'}} >{value.deal_text}</Typography>
            </Typography>
            <Typography sx={{textAlign:'center'}}component="div" variant="discount_info" sx={{lineHeight:'1'}}>{value.discount_text}</Typography>
            </Box>			
			
			<Typography component="div" variant="view_plans" sx={{height:'auto'}} >
				<Typography className="view_plans_link" variant="view_plans_link" style={{display:'block'}} sx={{position:{ xs: 'relative', sm: 'relative' },marginTop:{ xs: '0', sm: '2.2em' },}}>
				  <Typography className="wrapper_btn_view" sx={{'@media (max-width: 480px)': {width: '100%',fontSize:'1.4rem'} }} component="span" variant="wrapper_btn_view" >  VIEW PLANS </Typography>
				</Typography>
		  </Typography>
		  
          </Grid>
        </Box>
        </Link>   
        </Box>
  );
  }
}
