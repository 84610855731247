import * as React from 'react';
import PropTypes from 'prop-types';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Features,{offerData} from './components/Features';
import Footer from './components/Footer'; 
import getLPTheme from './getLPTheme';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './pages/About';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import Typography from '@mui/material/Typography';


function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
function setCookieSession(cname, cvalue) {
	var d = new Date();
	var hours = 24;
	d.setTime(d.getTime() + (hours * 1 * 60 * 60 * 1000));
	var expires = "expires="+d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  function showPopup(action) { 
      setCookieSession('exitpopup_shown_main', "no");
      $('#popup_form').css('z-index',1080);  
     
  }
  function popupClick(){
    var title = $('.offerlink').eq(0).attr('data-title');
    var datatrack = {
      name: title+' Popup clicked',
      lazy_pkey: localStorage.getItem('co_pkey'),
      engagement:'1',
      executeTime:500
  };
   
  var link = $('.offerlink').eq(0).attr('data-link');
  var hash = $('.offerlink').eq(0).attr('data-hash');
  window["lazysauce_action"](datatrack).then(function(successhash){
    console.log('successhash'+JSON.stringify(successhash));
    //window.lazysauce_action(adata).then(function(successhash){
      var url = link;
      url = url.replace('{HASH}',successhash);
      url = url.replace('{HASH}',successhash);
      url = url.replace('{CVAL}',"");
      url = url.replace('{SVAL}',"");
      
      window.open(url,'_blank'); 
    }).catch(() => {
      link = link.replace('{HASH}', hash);
      link = link.replace('{HASH}', hash);
      link = link.replace('{CVAL}',"");
      link = link.replace('{SVAL}',"");
      window.open(link,'_blank'); 
    });
  //setTimeout(function(){
    handleClose();
    $.ajax($('.offerlink').eq(0).attr('data-url'))
    .then((res) =>{ $('body').append(res)});
  
   // window.open(link,'_blank'); 
    //window.open($('.offerlink').eq(0).attr('href'),'_blank');

 // },1000);
    
  }
  // Initialize variables
  var idleTime = 0;
  var idleTime1 = 0;
  var idleInterval = setInterval(timerIncrement, 1000); // 1 second
  var idleInterval1 = setInterval(timerIncrement1, 1000); // 1 second
  var bottomReached = false;
  var tabCloseIntent = false; 
  // Increment idle time counter
  function timerIncrement1() {
  idleTime1++;
  }
  // Increment idle time counter
  function timerIncrement() {
  idleTime++;
  if (idleTime >= 15) { // 30 seconds
  var exitpopup_shown = checkCookieSession('exitpopup_shown_main');
  if(exitpopup_shown != 'no'){
    handleShow();
  showPopup('popup time');
  }
  } 
  }
  // Reset idle time counter when mouse or keyboard is used
  $(document).on('keydown mousemove scroll', function(e) {
  idleTime = 0;
  });
  // Reset idle time counter when form field is entered
  $('input, textarea').on('focus', function() {
  idleTime = 0;
  });
  // Show exit pop when user scrolls to the bottom of the page
  $(window).scroll(function() {
  if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
  bottomReached = true;
  var exitpopup_shown = checkCookieSession('exitpopup_shown_main');
  if(exitpopup_shown != 'no' && idleTime1 >= 5){
    handleShow();
  showPopup('popup bottom');
  }
  }
  });
  // Show exit pop when user intends to close the tab or window
  $(window).on('beforeunload', function() {
  tabCloseIntent = true;
  var exitpopup_shown = checkCookieSession('exitpopup_shown_main');
  if(exitpopup_shown != 'no' && idleTime1 >= 5){
  showPopup('popup top');
  handleShow();
  }
  });
  // Show exit pop when user moves cursor towards browser's back button or address bar
  $(document).on('mouseleave', function(e) {
  if (e.clientY < 0) {
  var exitpopup_shown = checkCookieSession('exitpopup_shown_main');
  if(exitpopup_shown != 'no' && idleTime1 >= 5){
    handleShow();
    showPopup('popup top');
  }
  }
  });
  function getCookieSession(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  function checkCookieSession(proName) {
    if (proName == "") {
      proName = "exitpopup_val";
    }
    var exitPopup = getCookieSession(proName);
    if (exitPopup === "no") {
      return 'no';
    } else{
      return 'yes';
    }
  }
  return ( 
  
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
	  <Router>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
		<Routes>
			<Route path="/about" element={<About />} />
		</Routes>      
      <Box sx={{ bgcolor: '#f5f5f5', fontSize: '1.06rem',color:'#212529' }}>
      <Modal
       show={show} onClick={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered  
    >
      <Modal.Body sx={{background:'none',height:'100%'}} onClick={handleClose}>
	    
      <Box className="popup-container" sx={{width:'450px', height: 'auto', margin: 'auto',   position: 'absolute',    top: '20%',    left: '50%',    transform: 'translate(-50%, 0)',    borderRadius: '10px',        padding: '5px'}} onClick={popupClick}>
      <button className="close" data-dismiss="modal" aria-hidden="true" onClick={handleClose}>×</button>
	  <Typography  component="div"  sx={{marginTop: '56%',position: 'absolute',verticalAlign: 'middle',textAlign: 'center',width: '98%'}}>
        <h3>{offerData.name} Offering</h3>
        <h2 className={offerData.name === "First Premier Home Warranty" ? "text-special" : ""}>
		{offerData.discount_text}
	    </h2>
      </Typography>
      <img src="assets/images/tr_exit_pop.jpg?v=567822" class="img-fluid" />
      </Box>
      </Modal.Body>
    </Modal>
        <Features />
       
        <Divider />
        <Footer />
      </Box>
	 </Router> 
    </ThemeProvider>
  );
}