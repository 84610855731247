import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export default function Content() { 
  const [vertical, setVertical] = React.useState({});

    React.useEffect(() => {
        async function getVertical() {
            const url = "https://ping.rebootmedia.net/get_vertical.php";
            let response = await fetch(url);
            response = await response.json();
            setVertical(response);
        }  
        getVertical();
    }, []);

    console.log('vertical HH' + JSON.stringify(vertical));
  return ( 
    <Container id="content" sx={{ py: { xs: 2, sm: 2 }, pl: { xs: 0, sm: 0 }}}>
        <Box>
        {vertical.middle_content && (
                    <div dangerouslySetInnerHTML={{ __html: vertical.middle_content }} />
                )} 
        </Box>
        </Container>
  );
}

