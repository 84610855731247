import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';

import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

import SitemarkIcon from './SitemarkIcon';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, textAlign:'center' }}>
	  <Typography component="div"  >Informed Choice owns and operates TrustedReviews.co</Typography>   
	  <Typography component="div"  >{'Copyright © '} {new Date().getFullYear()} Informed Choice, All Rights Reserved.</Typography>
    </Typography>


  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 3 },
        py: { xs: 3, sm: 3 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
		  className="footer-section"
          sx={{
            display: 'flex',
            gap: 4,
            minWidth: { xs: '100%', sm: '100%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '20%' } }}>
            <SitemarkIcon />
          </Box>
          <Box className="footer-menu" sx={{ width: { xs: '100%', sm: '80%' } }}>    
          <Link sx={{mr:'18px', fontWeight:'700', fontSize:'1rem'}}  color="text.secondary" variant="body2" href="#">
            About
          </Link>
          <Link sx={{mr:'18px', fontWeight:'700', fontSize:'1rem'}} color="text.secondary" variant="body2" href="https://informedchoicegroup.com/privacy-policy/">
            Privacy
          </Link>
          <Link sx={{mr:'18px', fontWeight:'700', fontSize:'1rem'}} color="text.secondary" variant="body2" href="https://informedchoicegroup.com/terms-of-use/">
            Terms of Use
          </Link>		  
          </Box>		  
		  
        </Box>


      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
		  flexDirection: 'column',
          pt: { xs: 1, sm: 0 },
          width: '100%',
        }}
      >
       <Typography sx={{ textAlign:'center', fontSize: '15px', fontWeight: 'normal',mb:'20px' }} color="text.secondary" component="div" >TrustedReviews.Net is designed to assist users in making confident online decisions, by providing information about products and services of various home warranty companies. Some details, such as prices and special offers are provided by our partners. Additional terms & conditions may apply. Many or all of the companies featured here provide compensation to us. These commissions are how we maintain our free service for consumers. Compensation may determine where & how companies appear on this site.</Typography>
	  
        <Box
		  sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: { xs: 1, sm: 0 },
          width: '100%',
        }}
		
		>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
}
