import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';

import Sitemark from './SitemarkIcon';

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: 0, bgcolor: '#fff', backgroundImage: 'none', mt: 0 }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            flexShrink: 0,
            pl: { xs: 0, sm: 6 },
            pr: { xs: 6, sm: 6 },
            maxHeight: 40,
          })}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Sitemark />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button onClick={openPopup}>Advertiser Disclosure</Button>
            </Box>			
          </Box>
        </Toolbar>
      </Container>

      {isPopupOpen && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" sx={{color:'#000' }}onClick={(e) => e.stopPropagation()}>
            <h2>Advertiser Disclosure</h2>
            <p>Trusted Reviews is an independent review site supported in part by affiliate commissions from some of the companies mentioned on this page. Our ratings and recommendations are based on our objective analysis, but advertiser relationships may impact where offers appear or the order in which companies are listed. While we strive to provide comprehensive coverage of the home warranty market, we do not include every plan or company available. For more information, please see our How We Evaluate page.</p>
            <Button onClick={closePopup} variant="contained" startIcon={<CloseRoundedIcon />}>
              Close
            </Button>
          </div>
        </div>
      )}
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
