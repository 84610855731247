import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
function getDate() {
  const today = new Date();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  return `${month} ${year}`;
}
const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '1px solid',
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${'/static/images/templates/templates-images/hero-light.png'})`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${'/static/images/templates/templates-images/hero-dark.png'})`,
    outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  }),
}));

export default function Hero() {
  const baseUrl ='https://trustedreviews.createch.company/assets/images/';
  const [offers, setOffers] = React.useState([])
  var offer1 = '';
  var offer2 = '';
  var offer3 = '';
  var stars_text = '';
  var rating_number1 = '';
  var rating_number2 = '';
  var rating_number3 = '';
  var rating_text1 = '';
  var rating_text2 = '';
  var rating_text3 = '';
  React.useEffect(() => {
    async function getOffers() {
      const url = "https://ping.rebootmedia.net/get_offers.php";
      let response = await fetch(url)
      response = await response.json()
      setOffers(response);
  }  
  getOffers();
  }, []);

  const [vertical, setVertical] = React.useState({});

  React.useEffect(() => {
      async function getVertical() {
          const url = "https://ping.rebootmedia.net/get_vertical.php";
          let response = await fetch(url);
          response = await response.json();
          setVertical(response);
      }  
      getVertical();
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',     background: '#f5f5f5'
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: { xs: 8, sm: 8 },
          pb: { xs: 4, sm: 4 },
          pl: { xs: 0, sm: 0 }, pr: { xs: 0, sm: 0 }
        }}  >
		
        <Box id="hero-bg" sx={{ minHeight:'280px',backgroundPosition:'90% 50%;', backgroundImage: {xs:`url(${'https://trustedreviews.createch.company/assets/images/'+vertical.desktop_banner})`,
		                                          md:`url(${'https://trustedreviews.createch.company/assets/images/'+vertical.mobile_banner})`},
		pt: { xs: 3, sm: 3 }, pb: { xs: 2, sm: 4 }, pl: { xs: 2, sm: 2, md: 10 }, pr: { xs: 1, sm: 10 },
       }}>
	     <Typography component="div" id="overlay" ></Typography>
         <Typography variant="h1" gutterBottom sx={{           
              color: 'white',
			  position:'relative',
			  lineHeight:'1.2',
			  zIndex:'2',
			  width: { xs: '100%', md: '60%',sm: '70%' },
			  fontSize: { xs: '1.4rem', md: '2.1rem',sm: '2rem' },
        '@media (max-width: 410px)': {width: '100%',fontSize:'1.3rem'}
            }}>Best {vertical.title2} of {(new Date().getFullYear())}</Typography>
         <Typography component="div" variant="header_text"  sx={{ lineHeight:'1.1',position:'relative',zIndex:'2', width: { xs: '100%', md: '45%',sm: '70%' }, fontSize: { xs: '1.2rem', md: '1.125rem',sm: '1.1rem' } }}
          >
          {vertical.paragraph}
          </Typography>

          <Typography component="div"   sx={{ position:'relative',zIndex:'2', width: { xs: '60%', md: '50%',sm: '100%' },mt: { xs: 3, sm: 3, md: 5, lg: 7 }, pl: { xs: 2.5, sm: 2.5 }, pr: { xs: 4, sm: 4 }, backgroundImage: `url(${'https://cdn.besthomewarranty.net/images/editor-tick.png'})`, backgroundSize: { xs: '7%', sm: '3%' }, fontSize:'0.775rem',color:'#fff',fontStyle: 'italic', backgroundPosition:'0 2px' }}  style={{ backgroundRepeat: 'no-repeat'}}          >
           Last Updated:  {getDate()} 
          </Typography>
      </Box> 
	  {/*<Box className="top-scroll-bg" sx={{ background: '#F5F5F5',ml: { xs: 2, sm: 6 }, mr: { xs: 2, sm: 6 },}}>
       <Typography className="top-text" sx={{ lineHeight:'2rem', width:'100%', }} component="div" variant='top_products_title'>Top 3 {vertical.title2}</Typography>    
         <Box className="top-card-row" sx={{ display:'flex',background: '#F5F5F5',pl: { xs: 1, sm: 1 }, pr: { xs: 1, sm:1 }, mt: { sm: 2, md: 1 }, }}> 
         {(Object.entries(offers) || []).map(([key, value]) => {            
       var rating_numbers = vertical.rating_number.split(',');
       var rating_texts = vertical.rating_text.split(',');
       var rating_number = rating_numbers[key];
      var  rating_text = rating_texts[key];
          if(key == 1) {
          offer1 = value;
          rating_number1 = rating_number;
          rating_text1 = rating_text;
          }
          else if(key == 0) {
            offer2 = value;
            rating_number2 = rating_number;
            rating_text2 = rating_text;
            var stars_text_arr = vertical.rating_stars_text.split('|');

          
            var stars = stars_text_arr[key].split(',');

            stars_text = stars.map((star, index) => {
               if (star == '1') 
                 return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-full.png`}  width="16" height="16" alt={offer2.name+' Rating'}/>)
               else if (star == '0.5') 
                 return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-haff.png`}  width="16" height="16" alt={offer2.name+' Rating'}/>)
               else 
               return( <img src={`${process.env.PUBLIC_URL}/assets/images/star-outline.png`}  width="16" height="16" alt={offer2.name+' Rating'}/>)
             
           })
          }
          else if(key == 2)
            offer3 = value;
          rating_number3 = rating_number;
          rating_text3 = rating_text;
        })
      }
            <Box className="top-card first-box-card" sx={{ background: '#fff', maxWidth: '353px', width: '100%', fontSize: '16px', fontWeight: 700,boxShadow: '0 0 7px rgba(0, 0, 0, 0.25)',color: '#2D2D2D', padding: '12px 12px 12px',marginBottom: '10px',marginRight: '10px', mb: { xs: 3, sm: 2, md: 2 },}}>
            
              <Typography  component="div" variant="card_header" sx={{display: 'flex',marginBottom:'5px'}}>
                <Typography component="div" >
                <Link href="#" underline="none">
                <img src={baseUrl+offer1.logo}  width="160" alt={offer1.name}/></Link>
                </Typography>
              </Typography>
              <Typography component="div" variant="card_footer" >
              <Typography component="div" variant="card_footer_text" >{offer1.discount_text}</Typography>
              <Link href="#" underline="none"><Typography component="h4" variant="activate_deal">Activate {offer1.name} Deal > </Typography></Link>
              </Typography>
            </Box>
            <Box className="top-card second-box-card" sx={{ background: '#fff', maxWidth: '353px', width: '100%', fontSize: '16px', fontWeight: 700,boxShadow: '0 0 7px rgba(0, 0, 0, 0.25)',color: '#2D2D2D', padding: '12px 12px 12px',marginBottom: '10px',marginRight: '10px',position:'relative', mb: { xs: 3, sm: 2, md: 2 },}}>
              <Typography className="score_text" component="div" variant="center_score_text">
                <Typography component="div" variant="center_score_bg">Best Value</Typography>
              </Typography>
              <Typography className="second-card-header" component="div" variant="card_header" sx={{display: 'flex',marginBottom:'5px'}}>
                <Typography component="div" >
                <Link href="#" underline="none">
                <img src={baseUrl+offer2.logo}  width="160" alt={offer2.name}/></Link>
                </Typography>
              </Typography>
              <Typography component="div" variant="card_footer" >
              <Typography component="div" variant="card_footer_text" >{offer2.discount_text}</Typography>
              <Link href="#" underline="none"><Typography component="h4" variant="activate_deal">Activate {offer2.name} Deal > </Typography></Link>
              </Typography>
            </Box> 
            <Box className="top-card third-box-card" sx={{ background: '#fff', maxWidth: '353px', width: '100%', fontSize: '16px', fontWeight: 700,boxShadow: '0 0 7px rgba(0, 0, 0, 0.25)',color: '#2D2D2D', padding: '12px 12px 12px',marginBottom: '10px',marginRight: '10px', mb: { xs: 3, sm: 2, md: 2 },}}>
            
              <Typography component="div" variant="card_header" sx={{display: 'flex',marginBottom:'5px'}}>
                <Typography component="div" >
                <Link href="#" underline="none">
                <img src={baseUrl+offer3.logo}  width="160" alt={offer3.name}/></Link>
                </Typography>
              </Typography>
              <Typography component="div" variant="card_footer" >
              <Typography component="div" variant="card_footer_text" >{offer3.discount_text}</Typography>
              <Link href="#" underline="none"><Typography component="h4" variant="activate_deal">Activate {offer3.name} Deal > </Typography></Link>
              </Typography>
            </Box>
            </Box>
	  </Box>*/}
      </Container> 
    </Box>
  );
}
