import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function Sidebar() {
    const [vertical, setVertical] = React.useState({});

    React.useEffect(() => {
        async function getVertical() {
            const url = "https://ping.rebootmedia.net/get_vertical.php";
            let response = await fetch(url);
            response = await response.json();
            setVertical(response);
        }  
        getVertical();
    }, []);
    console.log('vertical' + JSON.stringify(vertical));
    return (     
        <Box className="sidebar">
        <Typography component="div" variant="score_section" className="sidebar-section">
                {vertical.sidebar2 && (
                    <div dangerouslySetInnerHTML={{ __html: vertical.sidebar2 }} />
                )}
       {/*  <Typography component="div"  variant="score_section_sub">
            <Typography sx={{ fontSize: '1.1rem', fontWeight: '700', color: '#212529' }} component="div"  variant="score_section_title">How To Find The Best Home Warranty</Typography>
			<Typography sx={{ fontSize: '1rem', fontWeight: '700', color: '#212529' }} component="div"  variant="side-title">Get Protection For What You Need</Typography>
        </Typography>
        <Typography sx={{ fontSize: '0.9rem', lineHeight: '1.4em' }} component="p">A typical home warranty policy covers you for standard home systems, such as electrical, plumbing, heating & cooling. Some plans offer (free or as an upgrade) coverage for home appliances, such as refrigerators, washers, dryers, etc. Not all plans offer coverage for extras, such as roofing, pools or sewerage. So make sure you shop around to ensure the plan you buy actually covered everything you're worried about.</Typography>
		<Typography sx={{ fontSize: '1rem', fontWeight: '700', color: '#212529', lineHeight: '1.4em' }} component="div"  variant="side-title">Understand The Total Cost</Typography>
        <Typography sx={{ fontSize: '0.9rem', lineHeight: '1.2em' }} component="p">Don't get distracted by advertised monthly payments. There might be hidden fees so it's always a good idea to shop around.</Typography>
		<Typography sx={{ fontSize: '1rem', fontWeight: '700', color: '#212529', lineHeight: '1.4em' }} component="div"  variant="side-title">Shop Around For The Best Price</Typography>
		<Typography sx={{ fontSize: '0.9rem', lineHeight: '1.2em' }} component="p">Get several quotes to compare. The first place you can look is right here on our website! Most companies offer free quotes so you can do your research and find the right home warranty for you.</Typography> */}
		
		
        </Typography>
		
        <Typography component="div" variant="score_section" className="sidebar-section second-sidebar">
                {vertical.sidebar1 && (
                    <div dangerouslySetInnerHTML={{ __html: vertical.sidebar1 }} />
                )}
           {/*  <Typography component="div"  variant="score_section_sub">
                <Typography component="div"  variant="score_section_title">How We Score</Typography>
                <Typography component="div"  variant="side_bar"><img width="47" src="/assets/images/patches.png" alt="Trusted Reviews Score" /></Typography> 
            </Typography>
            <Typography component="p">Our editors review, score and rank products based on criteria such as:</Typography>
            
            <Box sx={{display:'flex',pt:'5px'}}>
                <Typography component="div"  variant="how_we_score">               
                    <Typography component="div"  variant="rank_list-1">
					    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
  component="img" src="/assets/images/features-benefits-alt.png" sx={{mr:'6px',width:"18px",height:"18px"}} alt=""  ></Box>
                        <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#212529' }} component="span"  variant="side-title" title="" data-original-title="Comprehensive Coverage" id="element1">Comprehensive Coverage</Typography>
						</Box>
						<p class="side-text">All the important parts of your home should be covered. Make sure that appliances are covered regardless of age or maintenance history.</p>
                    </Typography>
                   
                </Typography>
            </Box>

            <Box sx={{display:'flex',pt:'5px'}}>
                <Typography component="div"  variant="how_we_score">               
                    <Typography component="div"  variant="rank_list-1">
					    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
  component="img" src="/assets/images/customer-feedback-alt.png" sx={{mr:'6px',width:"18px",height:"18px"}} alt=""  ></Box>
                        <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#212529' }} component="span"  variant="side-title" title="" data-original-title="Customer Feedback" id="element1">Customer Feedback</Typography>
						</Box>
						<p class="side-text">Reviews from customers are critical to getting real insights into real world experiences from purchase through to after-sales service.</p>
                    </Typography>
                   
                </Typography>
            </Box>

            <Box sx={{display:'flex',pt:'5px'}}>
                <Typography component="div"  variant="how_we_score">               
                    <Typography component="div"  variant="rank_list-1">
					    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
  component="img" src="/assets/images/competitive-prices-alt.png" sx={{mr:'6px',width:"18px",height:"18px"}} alt=""  ></Box>
                        <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#212529' }} component="span"  variant="side-title" title="" data-original-title="Competitive Prices" id="element1">Competitive Prices</Typography>
						</Box>
						<p class="side-text">The point of home warranties is to protect your home and appliances without breaking the bank. Find the best home warranty within your budget. Remember to take advantage of any current deals or promotions!</p>
                    </Typography>
                   
                </Typography>
            </Box>

            <Box sx={{display:'flex',pt:'5px'}}>
                <Typography component="div"  variant="how_we_score">               
                    <Typography component="div"  variant="rank_list-1">
					    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
  component="img" src="/assets/images/reputation-alt.png" sx={{mr:'6px',width:"18px",height:"18px"}} alt=""  ></Box>
                        <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#212529' }} component="span"  variant="side-title" title="" data-original-title="Brand Reputation" id="element1">Brand Reputation</Typography>
						</Box>
						<p class="side-text">Brand reputation reflects a company's track record of delivering quality service and their reliability and trustworthiness over time. A company with a great brand reputation will usually make things right to protect that brand.</p>
                    </Typography>
                   
                </Typography>
            </Box>

            <Box sx={{display:'flex',pt:'5px'}}>
                <Typography component="div"  variant="how_we_score">               
                    <Typography component="div"  variant="rank_list-1">
					    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
  component="img" src="/assets/images/customer-service-alt.png" sx={{mr:'6px',width:"18px",height:"18px"}} alt=""  ></Box>
                        <Typography sx={{ fontSize: '15px', fontWeight: '700', color: '#212529' }} component="span"  variant="side-title" title="" data-original-title="Service & Support" id="element1">Service & Support</Typography>
						</Box>
						<p class="side-text">Customer support is one of the most important things for home warranties. You want to know that when something breaks down, you'll get responsive customer service and fast repair/replacement.</p>
                    </Typography>
                   
                </Typography>
            </Box> */}
        </Typography>		
		
        </Box>              
    )
    }